import { images } from '.';

const testimonials = [ 
    { 
        id: 1,
        image: images.testimonial1,
        name: 'jason roy',
        title: 'CEO',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    {
        id: 2,
        image: images.testimonial2,
        name: 'evelyn jones',
        title: 'manager',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    { 
        id: 3,
        image: images.testimonial3,
        name: 'viraj patel',
        title: 'client',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
        
    },
    { 
        id: 4,
        image: images.testimonial4,
        name: 'emma watson',
        title: 'client',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
        
    },
];

export default testimonials;