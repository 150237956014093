import React from 'react';
import './ContactInfo.css';

import { contactContent } from '../../constants';

 

const ContactInfo = () => {
  return (
 
      <div className='contact-info'>    
        {
          contactContent.map((contact) => {
            let details = contact.content;
            return (
            <div className='info-item' key={contact.id}>
                <div className='icon'>{contact.icon}</div>
                <h3>{contact.title}</h3>
                <ol>{details.map(item => {return <p>{item}</p>})}</ol>
            </div>
            )
          })
        }
      </div>

  )
}

export default ContactInfo;