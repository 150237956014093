import { images } from "../constants";

const wishlist = [
    {
        id: 1, 
        image: images.wishlist1,
        name: 'Cupcake',
        price: '$50.00',
        status: 'InStock',
    },
    { 
        id: 2,
        image: images.wishlist2,
        name: 'Cappuccino',
        price: '$100.00', 
        status: 'OutOfStock',
    },
    {
        id: 3,
        image: images.wishlist3,
        name: 'Doughnut',
        price: '$50.00',
        status: 'InStock',
    },
];

export default wishlist;