import { images } from '../constants'; 

const blogs = [   
    {   
        id: 1, 
        image: images.blog1,
        date : '5th feb',
        label: 'coffee',
        admin: 'admin',
        heading: 'design your coffee using whipped cream',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog1gallery1},
            {id: 2, image: images.blog1gallery2},
            {id: 3, image: images.blog1gallery3},
            {id: 4, image: images.blog1gallery4},
            {id: 5, image: images.blog1gallery5},
            {id: 6, image: images.blog1gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 2, 
        image: images.blog2,
        date : '2nd mar',
        label: 'coffee',
        admin: 'admin',
        heading: 'Best Cupcakes To Pair With Your Coffee',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog2gallery1},
            {id: 2, image: images.blog2gallery2},
            {id: 1, image: images.blog2gallery3},
            {id: 2, image: images.blog2gallery4},
            {id: 1, image: images.blog2gallery5},
            {id: 2, image: images.blog2gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 3, 
        image: images.blog3,
        date : '21st may',
        label: 'coffee',
        admin: 'admin',
        heading: 'Reasons Why You Should Drink Coffee In The Morning',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog3gallery1},
            {id: 2, image: images.blog3gallery2},
            {id: 1, image: images.blog3gallery3},
            {id: 2, image: images.blog3gallery4},
            {id: 1, image: images.blog3gallery5},
            {id: 2, image: images.blog3gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 4, 
        image: images.blog4,
        date : '15th jun',
        label: 'coffee',
        admin: 'admin',
        heading: 'Top 10 reasons why cold coffee is trending',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog4gallery1},
            {id: 2, image: images.blog4gallery2},
            {id: 1, image: images.blog4gallery3},
            {id: 2, image: images.blog4gallery4},
            {id: 1, image: images.blog4gallery5},
            {id: 2, image: images.blog4gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 5, 
        image: images.blog5,
        date : '25th jul',
        label: 'coffee',
        admin: 'admin',
        heading: '5 things to remember when making a latte',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog5gallery1},
            {id: 2, image: images.blog5gallery2},
            {id: 1, image: images.blog5gallery3},
            {id: 2, image: images.blog5gallery4},
            {id: 1, image: images.blog5gallery5},
            {id: 2, image: images.blog5gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 6, 
        image: images.blog6,
        date : '3rd oct',
        label: 'coffee',
        admin: 'admin',
        heading: 'How to brew coffee the right way',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog6gallery1},
            {id: 2, image: images.blog6gallery2},
            {id: 1, image: images.blog6gallery3},
            {id: 2, image: images.blog6gallery4},
            {id: 1, image: images.blog6gallery5},
            {id: 2, image: images.blog6gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export default blogs;