import { images } from '.';

export const coffee = [
    {
        id: 1,  
        name: 'Americano', 
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 2, 
        name: 'Cappuccino',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 3, 
        name: 'Macchiato',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 4,
        name: 'Mocha',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 5,
        name: 'latte',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 6, 
        name: 'Glacier',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 7, 
        name: 'black coffee',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 8, 
        name: 'instant coffee',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 9, 
        name: 'Espresso',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
];

export const drinks = [
    {
        id: 1,
        name: 'Hot Chocolate',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 2,
        name: 'White Chocolate',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 3,
        name: 'Vanila Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 4,
        name: 'Green Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 5, 
        name: 'earl grey',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 6,
        name: 'lemon',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 7, 
        name: 'motcha',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 8,
        name: 'black tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 9,
        name: 'apple cinnamon',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
];


export const desserts = [
    {
        id: 1,
        name: 'Sweets',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 2,
        name: 'Cupcake',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 3,
        name: 'Doughnut',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 4,
        name: 'Croissant',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 5,
        name: 'cheesecake',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 6, 
        name: 'chocolatecake',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 7,
        name: 'applepie',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 8, 
        name: 'brownie',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 9, 
        name: 'red velvet',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
];

