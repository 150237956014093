import { images } from '.'; 
 
const galleryContent = [
    {
        id: 1,
        image: images.gallery1,
        title: 'Latte',
        category: 'coffee',
        tag: 'coffee',
        link: '#'
    },
    {
        id: 2,
        image: images.gallery2,
        title: 'Cappuccino',
        category: 'coffee',
        tag: 'coffee',
        link: '#'
    },
    {
        id: 3,
        image: images.gallery3,
        title: 'Black Coffee',
        category: 'coffee',
        tag: 'coffee',
        link: '#',
    },
    {
        id: 4,
        image: images.gallery4,
        title: 'Hot Chocolate',
        category: 'hot drinks',
        tag: 'hot-drinks',
        link: '#'
    },
    {
        id: 5,
        image: images.gallery5,
        title: 'Black Tea',
        category: 'hot drinks',
        tag: 'hot-drinks',
        link: '#'
    },
    {
        id: 6,
        image: images.gallery6,
        title: 'Earl Grey',
        category: 'hot drinks',
        tag: 'hot-drinks',
        link: '#'
    },
    {
        id: 7,
        image: images.gallery7,
        title: 'Croissant',
        category: 'desserts',
        tag: 'desserts',
        link: '#'
    },
    {
        id: 8,
        image: images.gallery8,
        title: 'Cupcake',
        category: 'desserts',
        tag: 'desserts',
        link: '#'
    },
    {
        id: 9,
        image: images.gallery9,
        title: 'Doughnut',
        category: 'desserts',
        tag: 'desserts',
        link: '#'
    },
];

export default galleryContent;

