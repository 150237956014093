import React from 'react'; 
import './Testimonials.css';   

import { Heading } from '../../../components';
import { testiContent } from '../../../constants';

import { FaQuoteLeft } from 'react-icons/fa';
import { FaQuoteRight } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa'; 
 
// import Swiper core and required modules
import { Navigation,  Pagination, Autoplay } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles  
import 'swiper/css';
import 'swiper/css/pagination'; 
import 'swiper/css/navigation';
 
 

const Testimonials = () => { 
  return (
    <section className='testimonials' id='testimonials'>

      <Heading title={'testimonials'} span={'what our clients say'} />

      <div className='testimonial-slider'>
      <Swiper 
      // install Swiper modules
      modules={[Navigation, Pagination, Autoplay]}
      loop={true}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      navigation
      pagination={{ clickable: true }}
      >
        {
          testiContent.map((testimonial) => { 
            return (
              <SwiperSlide className='testi-item' key={testimonial.id}>

                <FaQuoteLeft className='quote-left'/>
                <FaQuoteRight className='quote-right'/>

                <img src={testimonial.image} alt='testimonial-pic' />

                <div className="rating">
                  <FaStar className='star'/>
                  <FaStar className='star'/>
                  <FaStar className='star'/>
                  <FaStar className='star'/>
                  <FaStar className='star'/>
                </div>
                <p>{testimonial.content}</p>
                <h3>{testimonial.name}</h3>
                <span>{testimonial.title}</span>

              </SwiperSlide>
            )
          })
        }

      </Swiper>
      </div>
      
    </section>

  )
} 

export default Testimonials