import React from 'react';
import './GalleryItem.css';
 
import { FaArrowRight } from 'react-icons/fa';

   
 
const Project = ({id, image, title, category, link, index, popup}) => {
  
  return (
    <div className='gallery-item' key={id}>
      <img src={image} alt='gallery-image' />
      <div className='content'>
        <h4>{title}</h4>
        <p>{category}</p>
        <a onClick={() => {popup(index)}}><FaArrowRight /></a> 
      </div>
    </div> 
  )
}

export default Project;