import React from 'react'; 
import './About.css';

import { Heading, Button } from '../../../components';
import { images } from '../../../constants';

   

const About = () => {   
  return (
    <section className='about' id='about'>

      <Heading title={'about us'} span={'know about us'} />

      <div className='box-container'>

        <div className='image'>
          <img src={images.about} alt='' />
        </div>

        <div class="content">
          <h2>Coffee the way it was meant to be.</h2>
          <h5>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Eum voluptatibus dolores aspernatur animi perferendis iste! </h5>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Eum voluptatibus dolores aspernatur animi perferendis iste! 
              Culpa aut enim debitis optio illum ipsum quis perferendis 
              dolor expedita. Similique necessitatibus inventore doloremque.</p>
          <Button link={'contact-us'} title={'contact us'} />
        </div>
 
      </div> 

    </section>
  )
}

export default About;