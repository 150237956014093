import { images } from '../constants';

const team = [ 
    {
        id: 1,
        Image: images.team1,
        name: 'Cristiano Harris', 
        title: 'CEO',
    },
    {
        id: 2,
        Image: images.team2,
        name: 'amy jones',
        title: 'Founder',
    },
    {
        id: 3,
        Image: images.team3,
        name: 'Paul Roger',
        title: 'manager',
    },
    {
        id: 4,
        Image: images.team4,
        name: 'Scarlet David',
        title: 'manager',
    },
 
];

export default team;