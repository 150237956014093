import React from 'react';
import './OrderList.css';

import { PageTitle } from '../../../components';
import { orderlist } from '../../../constants';

import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
 
 

const OrderList = () => { 
  return (
    <>
      <PageTitle title={'order list'} page={'order list'} />
      <section className='order-list'> 

        <div className='order-list-container'>
 
          <div className='container'>

            <div className='title'>
              <h3>order#ID</h3>
              <h3>date</h3>
              <h3>total</h3>
              <h3>status</h3>
              <h3>action</h3>
            </div>

            <div className='box-container'>
              {
                orderlist.map((item) => {
                  return(
                    <div className='item' key={item.id}>
                      <div className='box order-id'>{item.orderID}</div>
                      <div className='box price'>{item.date}</div>
                      <div className='box total'>{item.total}</div>
                      <div className={`box status ${item.status}`}>{item.status}</div>
                      <div className='box action'><Link to='/order-details' className='icon'><FaEye /></Link></div>
                    </div>
                  )
                })
              }

            </div>

          </div>

        </div>
      </section>
    </>
  )
}

export default OrderList;