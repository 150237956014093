import React from 'react';
import './Home.css';

import { Button } from '../../../components';
import { homeContent } from '../../../constants';

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from 'swiper';
 
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';   
import 'swiper/css/pagination';  
import 'swiper/css/navigation'; 

  

const Home = () => { 
  return (

    <section className='home' id='home'>

      <div className='home-slider'>

      <Swiper 
        // install Swiper modules
        modules={[Navigation, Pagination, Autoplay]}
        loop={true}
        autoplay={{ delay: 4000, disableOnInteraction: true }}
        navigation
        pagination={{ clickable: true }}
        >
          { 
            homeContent.map((home) => { 
              return (
              <SwiperSlide className='home-item' key={home.id}>
                
                <img src={home.image} alt='home-pic' />
   
                <div className='content'> 
                  <div className='text'> 
                    <h1>{home.title}</h1>
                    <p>{home.content}</p>
                    <div className='btn-container'>
                      <Button link={'menu'} title={'view menu'} className='home-btn' />
                      <Button link={'reservation'} title={'book table'} />
                    </div>
                  </div>
                </div>

              </SwiperSlide>
              )
            })
          }

        </Swiper>

      </div>

    </section>

  )
}

export default Home;