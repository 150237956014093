import React from 'react';

import { Footer, Header, ScrollTop } from './components';
import { HomePage, ContactUs, Gallery, AboutUs, TeamPage, TestimonialsPage, Faqs, BlogGrid, BlogList, BlogDetails, ShopGrid, ShopList, ProductDetails, Wishlist, Checkout, Cart, OrderList, OrderDetails, Login, Register, ForgotPwd, ChangePwd, Profile, EditProfile, EditAddress, MenuPage, Reservation } from './sections';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



const App = () => {
  return (
    <div className='App'>

      <Header />

      <Routes>

        {/*Home*/}
        <Route path='/' element={<HomePage />} />

        {/*About*/}
        <Route path='/about-us' element={<AboutUs />} /> 
        <Route path='/team' element={<TeamPage />} />
        <Route path='/testimonials' element={<TestimonialsPage />} />
        <Route path='/faqs' element={<Faqs />} />

        
        {/*Pages*/}
        <Route path='/menu' element={<MenuPage />} /> 
        <Route path='/gallery' element={<Gallery />} /> 
        <Route path='/reservation' element={<Reservation />} /> 
        <Route path='/login' element={<Login />} /> 
        <Route path='/register' element={<Register />} /> 
        <Route path='/forgot-pwd' element={<ForgotPwd />} /> 
        <Route path='/change-pwd' element={<ChangePwd />} /> 
        <Route path='/profile' element={<Profile />} /> 
        <Route path='/edit-profile' element={<EditProfile />} />  
        <Route path='/edit-address' element={<EditAddress />} />  


        {/*Blogs*/}
        <Route path='/blog-grid' element={<BlogGrid />} /> 
        <Route path='/blog-list' element={<BlogList />} /> 
        <Route path='/blogs/:id' element={<BlogDetails  />} />
        <Route path='/blog-details' element={<BlogDetails />} />

        {/*Shop*/}
        <Route path='/shop-grid' element={<ShopGrid />} /> 
        <Route path='/shop-list' element={<ShopList />} />
        <Route path='/shop/:id' element={<ProductDetails />} />
        <Route path='/product-details' element={<ProductDetails />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/order-list' element={<OrderList />} />
        <Route path='/order-details' element={<OrderDetails />} />
 
        {/*Contact*/}
        <Route path='/contact-us' element={<ContactUs />} />

      </Routes>

      <Footer />
      <ScrollTop />

    </div>
  )
}

export default App;
