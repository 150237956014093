import React from 'react';

import { PageTitle, MenuSection } from '../../../components';



const MenuPage = () => {

  return (
    <>
        <PageTitle title={'our menu'} page={'menu'} />
        <MenuSection />
    </>
  )
}

export default MenuPage;