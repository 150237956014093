import React, { useEffect, useState } from 'react';
import './MenuSection.css';

import { MenuList } from '../../components';
import { coffee, drinks, desserts } from '../../constants'; 


 
const Menu = () => {

    const [selected, setSelected] = useState("herbal"); 
    const [data, setData] = useState([]);

    const list = [
        {
          id: "coffee",
          title: "coffee",
        },
        {
          id: "drinks",
          title: "hot drinks",
        },
        {
          id: "desserts",
          title: "desserts",
        },
    ];

    useEffect(() => {
        switch (selected) {
          case "coffee":
            setData(coffee);
            break;
          case "drinks":
            setData(drinks);
            break;
          case "desserts":
            setData(desserts);
            break;
          default:
            setData(coffee);
        }
    }, [selected]);

  return (

        <div className='menu'>

            <ul class="menu-btn-tabs">
            { 
                list.map((item) => {
                return <MenuList
                id={item.id} 
                title={item.title}
                active={selected === item.id} 
                setSelected={setSelected} />
                })
            }
            </ul> 

            <div className='box-container'>
            {
                data.map((d) => {
                return (
                    <div className='menu-item'>
                        <div class="intro">
                            <h4>{d.name}</h4>
                            <span class="price">${d.price}</span>
                        </div>
                        <p>{d.content}</p>
                    </div>
                )
                })
            }
            </div>

        </div>
  )
}

export default Menu;