import React from 'react';
import './MenuList.css';
 
 

const MenuList = ({id, title, active, setSelected}) => {
  return ( 
    <li
        className={active ? "button btn active" : "button btn"}
        onClick={() => setSelected(id)}>
        {title}
    </li>
  )
}
  
export default MenuList; 