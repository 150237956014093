import React from 'react';
import {Home, About, Services, Products, Menu, Team, Testimonials, Blogs, Contact} from '../../sections'; 

 
const HomePage = () => { 
  return ( 
      <div className='homepage'>
        <Home />
        <About /> 
        <Services />
        <Products /> 
        <Menu />
        <Team />
        <Testimonials />
        <Blogs />
        <Contact />
      </div>
  )
}

export default HomePage;