import React from 'react';
import './WishlistItem.css'; 

import { BsFillCartPlusFill } from 'react-icons/bs';
import { RiDeleteBin5Fill } from 'react-icons/ri';

import { Link } from 'react-router-dom'; 

  
 
const WishlistItem = ({id, image, name, price, status}) => {
  return (
    <div className='item' key={id}>
      <img className='box image' src={image} alt='' />
      <div className='box name'>{name}</div>
      <div className='box price'>{price}</div>
      <div className={`box status ${status}`}>{status}</div>
      <div className='box action'>
        <Link to='/#'><RiDeleteBin5Fill className='icon delete' /></Link> 
        <Link to='/#'><BsFillCartPlusFill className='icon cart' /></Link> 
      </div>
    </div>
    
  )
}

export default WishlistItem;