import { images } from "../constants";

export const cartItems = [
    {
        id: 1,  
        image: images.cart1,
        name: 'Cupcake',
        price: '$50.00',
        quantity: '1',
        total: '$50.00',
    },
    {
        id: 2,
        image: images.cart2,
        name: 'Cappuccino',
        price: '$100.00', 
        quantity: '1',
        total: '$100.00',
    },
    {
        id: 3,
        image: images.cart3,
        name: 'Doughnut',
        price: '$50.00',
        quantity: '1',
        total: '$50.00',
    },
];

 
export const cartSummary = [
    {id: 1, name: 'sub total', value: '$200.00'},
    {id: 2, name: 'delivery fee', value: '$50.00'},
    {id: 3, name: 'discount', value: '$20.00'},
    {id: 4, name: 'tax', value: '$20.00'},
    {id: 5, name: 'total', value: '$250.00'},
];