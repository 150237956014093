import React from 'react';
import './Reservation.css';

import { Button, PageTitle } from '../../../components';

import { Link } from 'react-router-dom';


 
const Reservation = () => {  
  return (   
    <>
        <PageTitle title={'reservation'} page={'reservation'} /> 
        <section className='reservation'>

            <div className="box-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924234.6303368191!2d66.59494515823903!3d25.193389461148573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1645287442276!5m2!1sen!2s" allowfullscreen="" loading="lazy"></iframe>
                <form method="post" className='booking-table' action="example.php">
                    <h2>book your table</h2>
                    <div className="input-box">
                        <input type="text" name="name" className="box" id="name" placeholder="name" required />
                        <input type="email" name="email" className="box" id="email" placeholder="email" required />
                    </div>
                    <div className="input-box">
                        <input type="number" name="number" className="box" id="number" placeholder="number" required />
                        <input type="date" name="date" className="box" id="date" required />
                    </div>
                    <div className="input-box">
                        <input type="time" name="time" className="box" id="time" required />
                        <input type="number" name="guests" className="box" id="guests" placeholder="guests" required />
                    </div>

                    <textarea cols="30" rows="10" name="comment" className="box" id="comment" placeholder="message"></textarea>
                    <button type="submit"  name="submit" id="submit"><Button title={"book now"} /></button>
                    
                    <span className="alert" id="msg"></span>
                </form>
            </div> 
            
        </section> 
    </>

  )
}

export default Reservation;