import { images } from '../constants';

const home = [
    {  
        id: 1,
        image: images.home1,
        title: 'Live your best coffee life',
        content: 'We dont make your coffee. We make your day.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'A cup of coffee for every mood',
        content: 'We dont make your coffee. We make your day.',
    },
    { 
        id: 3,
        image: images.home3,
        title: 'We make coffee more enjoyable',
        content: 'We dont make your coffee. We make your day.',  
    },

];

export default home;