import React from 'react';
import './BlogInfo.css';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

 

const BlogInfo = (props) => { 
         
    const {blog} = props;
    const paragraphs = blog.paragraph; 
  
    return(
    <div className='blog-info' key={blog.id}>
        <span class="date">{blog.date}</span>
        <div className='image'>
            <img src={blog.image} alt='Blog' />
        </div>
        <div className='content'> 

            <div className='details'>
                <h5 class="title"><span>{blog.label}</span></h5>
                <h5 class="admin"><span>by {blog.admin}</span></h5>
            </div>

            <h3 class="main-heading">{blog.heading}</h3>

            <p>{paragraphs.para1}</p>  
            <p>{paragraphs.para2}</p> 
             
            <div class="important">
                {blog.important}
            </div>

            <p>{paragraphs.para3}</p>  
            <p>{paragraphs.para4}</p> 

            <div className='blog-gallery'>
            {
                (blog.gallery).map((item) => {
                    return <img src={item.image} alt='' />
                })
            }
            </div> 
            
            <p>{paragraphs.para5}</p>  
            <p>{paragraphs.para6}</p> 

            <div class="end-details">
                <div class="tags">
                    <h3>tags:</h3>
                    {
                        (blog.tags).map((item) => {
                            return <span>{item.title}</span>
                        })
                    }
                </div>

                <div class="share">
                    <h3>share:</h3>
                    <FaFacebookF className='icon' />
                    <FaLinkedin className='icon' />
                    <FaTwitter className='icon' />
                    <FaInstagram className='icon' />
                </div>
            </div>
        </div>
    </div>
    )
}

export default BlogInfo;