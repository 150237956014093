import React from 'react';
import './Logo.css';

import { images } from '../../constants';
 
import { Link } from "react-router-dom";

 
  
const Logo = () => { 
  return (
    <Link to='/' className='logo'>
      <img src={images.logo} alt='header-logo' />
      <h3>coffee point</h3>
    </Link> 
  )
}

export default Logo;