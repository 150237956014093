import React from 'react';
import './Blog.css';

import { Link } from 'react-router-dom'; 


 
const Blog = (props) => { 
  const {image, heading, content, blog, label, admin, date} = props;
  return (  
    <div className='blog-item'> 
      <span class="date">{date}</span>
      <div className='image'>
        <img src={image} alt='Blog' />
      </div> 
      <div className='content'>
        <div className='details'>
          <h5 class="title"><span>{label}</span></h5>
          <h5 class="admin"><span>by {admin}</span></h5>
        </div>
        <Link to={{
          pathname: '/Blogs/' + heading,
        }} 
        state= {blog}
        className='main-heading'>{heading}
        </Link>
        <p>{content}</p>    
      </div>

    </div>
  )
}

export default Blog;