import React from 'react';
import './Product.css';  

import { FaStar } from 'react-icons/fa';
import { BsCartPlusFill } from 'react-icons/bs';

import { Link } from 'react-router-dom';   
  
     

const Product = ({image, name, product, content, disprice, price}) => { 

  return (
    <div className='product-item'>
      <div className='options'>
        <Link to='/cart'><BsCartPlusFill className='icon' /></Link>
      </div>
      <div className='image'>
        <img src={image} alt='' />
      </div>
      <div className='content'>
        <Link to={{
          pathname: '/Shop/' + name,
        }} 
        location = {{product}}
        state= {product}><h3>{name}</h3> 
        </Link>
        <p>{content}</p>
        <div className='rating'>
          <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
        </div>
        <div className='price'>
          <p>${disprice} <span>${price}</span></p>
        </div>
      </div>
    </div> 
  )
} 

export default Product;