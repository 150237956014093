import { images } from '.';


const services = [ 
    {
        id: 1,
        image: images.service1,
        title: 'selected coffee beans',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 2,
        image: images.service2,
        title: 'own roasting',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 3,
        image: images.service3,
        title: 'high quality',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 4,
        image: images.service4,
        title: 'freshly brewed',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 5,
        image: images.service5,
        title: 'coffee variety',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 6,
        image: images.service6,
        title: 'Excellent grinding',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
];

export default services;