import React from 'react';
import { ContactInfo, Heading, PageTitle } from '../../../components';
import { Team } from '../../../sections';


const Contact = () => {
  return (
    <section className='contact'>
        <Heading title={'contact us'} />   
        <ContactInfo />  
    </section>
  )
}

export default Contact;