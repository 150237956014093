import { images } from '../constants';

export const categories = [  
    {
        id: 1,
        title: 'coffee',
        quantity: '10',
    },
    {
        id: 2,
        title: 'hot drinks',
        quantity: '15',
    }, 
    {
        id: 3, 
        title: 'desserts',
        quantity: '8',
    },
];

export const posts = [ 
    {   
        id: 1, 
        image: images.post1,
        date : '23rd apr',
        label: 'coffee',
        admin: 'admin',
        heading: '7 reasons people love the smell of coffee',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post1gallery1},
            {id: 2, image: images.post1gallery2},
            {id: 3, image: images.post1gallery3},
            {id: 4, image: images.post1gallery4},
            {id: 5, image: images.post1gallery5},
            {id: 6, image: images.post1gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.post1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.post1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 2, 
        image: images.post2,
        date : '3rd jul',
        label: 'coffee',
        admin: 'admin',
        heading: '10 reasons why coffee is good for your health',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog2gallery1},
            {id: 2, image: images.blog2gallery2},
            {id: 1, image: images.blog2gallery3},
            {id: 2, image: images.blog2gallery4},
            {id: 1, image: images.blog2gallery5},
            {id: 2, image: images.blog2gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 3, 
        image: images.blog5,
        date : '15th sep',
        label: 'coffee',
        admin: 'admin',
        heading: '3 ways to make a winning coffee',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog5gallery1},
            {id: 2, image: images.blog5gallery2},
            {id: 1, image: images.blog5gallery3},
            {id: 2, image: images.blog5gallery4},
            {id: 1, image: images.blog5gallery5},
            {id: 2, image: images.blog5gallery6},
        ],
        tags: [ 
            {id: 1, title: 'coffee'},
            {id: 2, title: 'cream'},
            {id: 3, title: 'desserts'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Javeed Raheem',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Emily Warner',
                        date: 'June 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'John David',
                date: 'June 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export const tags = [
    {
        id: 1,
        title: 'coffee',
    },
    {
        id: 2,
        title: 'hot drinks',
    },
    {
        id: 3,
        title: 'tea',
    },
    {
        id: 4,
        title: 'desserts', 
    },
    {
        id: 5,
        title: 'cake', 
    },
    {
        id: 6,
        title: 'Croissant',
    }, 
];