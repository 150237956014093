import React from 'react';
import './ShopGrid.css';

import { PageTitle, ShopSidebar, ShopHeader, Product, PagesNo } from '../../../components';
import { productsContent } from '../../../constants';



const ShopGrid = () => {  
    
  const size = 9;
  const products = productsContent.slice(0, size);
  
  return (   
    <> 
      <PageTitle title={'shop grid'} page={'shop grid'} />
        <section className='shop'>
            <ShopSidebar />
            <div className='shop-container'>
              <ShopHeader showing='9' total='10' /> 
                <div className='product-container grid'>
                { 
                  products.map((product) => {
                    return( 
                      <Product  
                      key={product.id} 
                      image={product.image}
                      name={product.name}
                      disprice={product.disprice}
                      price={product.price}
                      product={product} />
                    )
                  }) 
                }
                </div>
                <PagesNo />
            </div>
        </section>
    </>
  )
}

export default ShopGrid;