import React, { useEffect, useState } from 'react';
import './Products.css'; 

import { productsContent } from '../../../constants'; 
import { Heading, Product } from '../../../components';

 
 
const Products = () => { 

  const size = 8;
  const products = productsContent.slice(0, size);

  return (
  <section className='products'>

    <Heading title={'our products'} />

    <div className='box-container'>
      {
        products.map((product) => {
          return ( 
            <Product  
            key={product.id} 
            image={product.image}
            name={product.name}
            disprice={product.disprice}
            price={product.price}
            product={product} />
          )
        })
      }
    </div>

  </section>
  )
}

export default Products;