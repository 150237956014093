import React from 'react';
import './Profile.css';

import { Address, Button, Heading, PageTitle } from '../../../components';
import { profile } from '../../../constants';

   
   
const Profile = () => {
    const addressDetails = profile.address;
  return ( 
     <> 
        <PageTitle title={'profile'} page={'profile'} />
        <section class="profile">

            <div class="box-container"> 
                
                <div class="profile-item profile-details">
                    <Heading title={'profile details'} />
                    <div class="content">
                        <img src={profile.image} alt="" />
                        <h3>{profile.name}</h3>
                        <p class="gmail">{profile.gmail}</p>
                        <p>{profile.contactNo}</p>
                        <Button link={'/edit-profile'} title={'edit profile'} />
                    </div> 
                </div>
 
                <div class="profile-item address-details">
                    <Heading title={'address details'} />
                    <div class="content">
                        <div class="address-container">
                            {
                                addressDetails.map((item) => {
                                    return(
                                        <Address 
                                        id={item.id}
                                        title={item.title}
                                        name={item.name}
                                        country={item.country}
                                        city={item.city}
                                        province={item.province}
                                        ZIP={item.ZIP}
                                        address={item.address} />
                                    )
                                })
                            }
                        </div> 
                        <Button link={'/edit-address'} title={'edit address'} />
                    </div>

                </div>
                
            </div>

        </section>
    </> 
  )
}

export default Profile;