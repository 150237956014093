import React from 'react';

import { Heading, MenuSection } from '../../../components';

 

const Menu = () => {
  return (
    <>
      <Heading title={'our menu'} />
      <MenuSection />
    </>
  )
}

export default Menu;