import { images } from '../constants';

const products = [
    {
        id: 1,
        image: images.product1Gallery1, 
        name: 'Cupcake',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',        
        reviews: '100+', 
        gallery: {
            image1: images.product1Gallery1,
            image2: images.product1Gallery2,
            image3: images.product1Gallery3,
            image4: images.product1Gallery4,
        },
    },
    {
        id: 2,
        image: images.product2Gallery1, 
        name: 'Cappuccino',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '150+',
        gallery: {
            image1: images.product2Gallery1,
            image2: images.product2Gallery2,
            image3: images.product2Gallery3,
            image4: images.product2Gallery4,
        }
    }, 
    {
        id: 3,
        image: images.product3Gallery1, 
        name: 'Doughnut',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',        
        reviews: '50+',
        gallery: {
            image1: images.product3Gallery1,
            image2: images.product3Gallery2,
            image3: images.product3Gallery3,
            image4: images.product3Gallery4,
        }
    },
    {
        id: 4,
        image: images.product4Gallery1, 
        name: 'Latte',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '50+',
        gallery: {
            image1: images.product4Gallery1,
            image2: images.product4Gallery2,
            image3: images.product4Gallery3,
            image4: images.product4Gallery4,
        }
    },
    {
        id: 5,
        image: images.product5Gallery1, 
        name: 'chocolate cake',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',        
        reviews: '30+',
        gallery: {
            image1: images.product5Gallery1,
            image2: images.product5Gallery2,
            image3: images.product5Gallery3,
            image4: images.product5Gallery4,
        }
    },
    {
        id: 6,
        image: images.product6Gallery1, 
        name: 'hot chocolate',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',        
        reviews: '60+',
        gallery: {
            image1: images.product6Gallery1,
            image2: images.product6Gallery2,
            image3: images.product6Gallery3,
            image4: images.product6Gallery4,
        }
    },
    {
        id: 7,
        image: images.product7Gallery1, 
        name: 'Croissant',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',        
        reviews: '70+',
        gallery: {
            image1: images.product7Gallery1,
            image2: images.product7Gallery2,
            image3: images.product7Gallery3,
            image4: images.product7Gallery4,
        }
    },
    {
        id: 8,
        image: images.product8Gallery1, 
        name: 'green tea',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',        
        reviews: '75+',
        gallery: {
            image1: images.product8Gallery1,
            image2: images.product8Gallery2,
            image3: images.product8Gallery3,
            image4: images.product8Gallery4,
        }
    },
    {
        id: 9, 
        image: images.product9Gallery1, 
        name: 'chocolate',
        price: '15.00',
        disprice: '12.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',        
        reviews: '120+',
        gallery: {
            image1: images.product9Gallery1,
            image2: images.product9Gallery2,
            image3: images.product9Gallery3,
            image4: images.product9Gallery4,
        }
    },
 
];

export default products;