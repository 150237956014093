import React from 'react';
import './Footer.css';

import { Logo } from '../../components';
 
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaArrowRight } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { BsMapFill } from 'react-icons/bs';

import { Link } from 'react-router-dom';
 
 
  
const Footer = () => {   
  
  return (
    <footer className='footer'>

      <div className='box-container'>

        <div className='footer-item'> 
          <Logo />
          <p>       
            Our coffee is always the way you like.
            We don't make your coffee. We make your day.
            We’re not just a cup of coffee. We’re a lifestyle.
          </p>
        </div>

        <div className='footer-item'>
          <h2>quick links</h2>
          <div className='info links'> 
            <p><FaArrowRight className='icon' /><Link to='/'>home</Link></p> 
            <p><FaArrowRight className='icon' /><Link to='/about-us'>about</Link></p>
            <p><FaArrowRight className='icon' /><Link to='/menu'>menu</Link></p>
            <p><FaArrowRight className='icon' /><Link to='/blog-grid'>blog</Link></p>
            <p><FaArrowRight className='icon' /><Link to='/shop-grid'>shop</Link></p>
            <p><FaArrowRight className='icon' /><Link to='/contact-us'>contact</Link></p>
            <p><FaArrowRight className='icon' /><Link to='/reservation'>reservation</Link></p>
          </div>
        </div> 

        <div className='footer-item'>
          <h2>contact info</h2>
          <div className='info'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><AiTwotonePhone className='icon' /><span>+123-456-789</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">abc@gmail.com</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">xyz@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
          <div class="social">
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div>
        </div>

      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;