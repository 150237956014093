import React from 'react';
import './PageTitle.css';

import { Link } from 'react-router-dom';

 

const PageTitle = ({title, page}) => {
  return (  
    <div className='page-title'> 
    
      <h2>{title}</h2>
  
      <div className='link'>
        <Link to='/'>home</Link>
        <span className='slash'>/</span>
        <span className='page'>{page}</span>
      </div>

    </div>
  )
}

export default PageTitle;