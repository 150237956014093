import React from 'react';
import './PriceFilter.css';

import Slider from '@material-ui/core/Slider';

const PriceFilter = () => {

   
    // Our States
    const [value, setValue] =  React.useState([0,500]);
  
    // Changing State when volume increases/decreases
     const rangeSelector = (event, newValue) => {
        setValue(newValue);
        console.log(newValue) 
    };

    return (
        <div style={{
        margin: 'auto',
        display: 'block',
        width: 'fit-content'
        }}>
            <Slider
                value={value}
                onChange={rangeSelector}
                valueLabelDisplay="auto"
            />
            <div className='price-wrap'>
                <span className='filter-btn'>FILTER</span>
                <div className='price-container'>
                    <div className='price-wrap-1' id='lower'>
                        <label>$</label>
                        <p>{value[0]}</p>
                    </div>
                    <div className='price-wrap_line'>-</div>
                    <div className='price-wrap-2' id='upper'>
                        <label for="two">$</label>
                        <p>{value[1]}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PriceFilter;