import { AiFillShopping } from 'react-icons/ai';
import { BsFillEmojiSmileFill, BsFillAwardFill } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';

  
const counter = [
    {  
        id: 1,
        icon: <AiFillShopping/>,
        title: 'products',
        count: '1000',
    },
    {
        id: 2,
        icon: <BsFillEmojiSmileFill />,
        title: 'satisfied customers',
        count: '500',
    },
    {
        id: 3,
        icon: <BsFillAwardFill />,
        title: 'awards won',
        count: '100',
    },
    {
        id: 4,
        icon: <FaUsers />,
        title: 'staff',
        count: '50',
    },
 
];

export default counter;