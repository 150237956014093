import React, {useState, useEffect} from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar, Logo } from '../../components';
import { navbar } from '../../constants';

import { FaBars, FaUserAlt, FaCartArrowDown } from 'react-icons/fa';

import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
  
    
    
const Header = () => { 
  const [click, setClick] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const {pathname} = useLocation();

  const handleClick = () => {
    setClick(true); 
    setOpen(true); 
  }

  const closeMenuBar = () => {
    setClick(false);
    setOpen(false); 
  }

  /* Close the Navbar between navigation */
  useEffect(() => {
    setOpen(false); 
  }, [ pathname ]);
     
  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = (e) => {
    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);
    setOpen(false); 
  };



  return (  

    <nav className='header'>
 
      <Logo />
 
      {(click && isOpen) && <MobileNavbar close={closeMenuBar} />}

      <div className='navbar' >
        {
          navbar.map((item) => {
            return(
              <HeaderItem id={item.id} {...item} />
            )
          })
        }
      </div>

      <div className='icon-container'>
        <div class="icon menu-btn" id='menu' onClick={handleClick}><FaBars /></div>
        <Link class="icon" id='login' to='/login'><FaUserAlt /></Link>
        <Link class="icon" id='cart' to='/cart'><FaCartArrowDown /></Link>
      </div>

    </nav>
   )
 }
 
 export default Header;