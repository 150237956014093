import React from 'react';
import './Button.css'; 


const Button = ({title, link=''}) => {
  return (
    <a href={`${link}`} className="btn">{title}</a>    
  )  
}
 
export default Button