import React from 'react';
import './CommentForm.css';

import { Button } from '../../components';
 
 

const CommentForm = () => {
  return (
    <div className='leave-reply'>
      <form className='form' action='comment.php'>
          <h3>leave a comment</h3>  
          <div className='input-box'>
              <input type="text" name="name" className='box' id="name" placeholder="name" required />
              <input type="email" name="email" className='box' id="email" placeholder="email" required />
          </div>
          <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>
          <button type="submit" name="submit" id="submit"><Button title={"submit"} /></button>
      </form>
    </div>
  )
}

export default CommentForm;